$(document).ready(function () {
   openMenu();
   closeMenu();
   scrollHandler();
   datepickerInit();
   setCurrentDate();
   initSlider('slider-one');
   initSlider('slider-rubric');
   copyUrlHandler();
   selectAllHandler();
   whipOffHandler();
   toApplyHandler();
   rubricListHandler();
   rubricFilterHandler();
   showMoreFilterButtonHandler();
   filterListHandler();
   filterWhipOffHandler();
   showFilterPopupHandler();
   closeFilterPopupHandler();
   hashtagListHandler();
   filterToApplyPopup();
});

$(window).on('load', function () {
   console.log('load');
});

function openMenu () {
   $(document).on('click', '.header__icon-burger', function () {
      const ths = $(this);
      ths.addClass('open');
      refs.menuContainer.addClass('menu-open');
      refs.body.addClass('no-scroll');
   });
}

function closeMenu () {
   $(document).on('click', '.menu__close', function () {
      refs.body.removeClass('no-scroll');
      refs.menuContainer.removeClass('menu-open');
   });
}

function scrollHandler () {
   $(window).scroll(function () {
      let height = $(window).scrollTop();

      if (height === 0) refs.headerLogo.addClass('is-hidden');
      if (height > 0) refs.headerLogo.removeClass('is-hidden');
   });
}

function datepickerInit () {
   $("#datepicker").datepicker({
      showOn: "button",
      buttonImage: "/static/app/img/calendar.svg",
      buttonImageOnly: true,
      altField: "#alternate",
      altFormat: "DD, d MM",
      autoSize: true,
      // appendText: $.datepicker.formatDate('DD, d MM', new Date()),
      onSelect: function (dateText) {
         const selectedDate = dateText;
         // console.log("Selected date: " + dateText + "; input's current value: " + this.value);
         console.log('change date');
      },
   });
}

function setCurrentDate () {

   const date = new Date();

   const currentDate = $.datepicker.formatDate("DD, d MM", date);

   $('#alternate').val(currentDate);
}

function copyUrlHandler () {
   $(document).on('click', '[data-copy]', function () {

      const location = window.location.href;
      navigator.clipboard.writeText(location);
   });
}

function selectAllHandler () {
   $(document).on('click', '[data-select-all]', function () {
      selectAll(
         refs.rubricListItem,
         refs.buttonToApply,
      );

      selectAll(
         refs.hashtagListItem,
         refs.buttonToApply,
      );

      // const list = refs.rubricListItem;
      //
      // refs.buttonToApply.addClass('active').prop("disabled", false);
      //
      // list.each(function () {
      //    const ths = $(this);
      //    ths.addClass('active');
      // });
   });
}

function selectAll (list, button) {
   button.addClass('active').prop("disabled", false);

   list.each(function () {
      const ths = $(this);
      ths.addClass('active');
   });
}

function whipOffHandler () {
   $(document).on('click', '[data-whip-off]', function () {

      filterWhipOff(
         refs.rubricListItem,
         refs.buttonToApply,
      );

      filterWhipOff(
         refs.hashtagListItem,
         refs.buttonToApply,
      );
   });
}

function toApplyHandler () {
   $(document).on('click', '[data-to-apply]', function () {
      console.log('apply');
   });
}

function rubricFilterHandler () {
   $(document).on('click', '[data-filter-wrapper]', function (e) {
      const target = $(e.target);
      if (target.closest("[data-filter-button]").length === 0) return;

      console.log('delete button');
   });
}

function showMoreFilterButtonHandler () {
   $(document).on('click', '[data-filter-button-more]', function () {
      const ths = $(this);

      showMoreFilterButton(refs.rubricFilterButton);
      showMoreFilterButton(refs.hashtagFilterButton);

      ths.addClass('hidden');
   });
}

function showMoreFilterButton (buttons) {
   buttons.each(function () {
      const ths = $(this);
      ths.removeClass('hidden');
   });
}

function rubricListHandler () {
   $(document).on('click', '.rubric-list__list', function (e) {
      const target = $(e.target);
      const button = ".rubric-list__item";

      if (target.closest(button).length === 0) return;

      filterList(
         refs.rubricListItem,
         target,
         refs.buttonToApply,
         button,
      );
      // const list = refs.rubricListItem;
      // target.closest(".rubric-list__item").toggleClass('active');
      //
      // list.each(function () {
      //    const ths = $(this);
      //    const isTrue = ths.hasClass('active');
      //
      //    if (!isTrue) {
      //       return refs.buttonToApply.removeClass('active').prop("disabled", true);
      //    }
      // });
      //
      // list.each(function () {
      //    const ths = $(this);
      //    const isTrue = ths.hasClass('active');
      //
      //    if (isTrue) {
      //       return refs.buttonToApply.addClass('active').prop("disabled", false);
      //    }
      // });
   });
}

function hashtagListHandler () {
   $(document).on('click', '.hashtag-list__list', function (e) {
      const target = $(e.target);
      const button = ".hashtag-list__item";

      if (target.closest(button).length === 0) return;

      filterList(
         refs.hashtagListItem,
         target,
         refs.buttonToApply,
         button,
      );
   });
}

function filterListHandler () {
   $(document).on('click', '[data-filter-container]', function (e) {
      const target = $(e.target);
      const button = "[data-filter-button-popup]";

      if (target.closest(button).length === 0) return;

      filterList(refs.filterButtonPopup,
         target, refs.buttonToApplyFilterPopup,
         button,
      );

      // const list = refs.filterButtonPopup;
      // target.closest("[data-filter-button-popup]").toggleClass('active');
      //
      // list.each(function () {
      //    const ths = $(this);
      //    const isTrue = ths.hasClass('active');
      //
      //    if (!isTrue) {
      //       return refs.buttonToApplyFilterPopup.removeClass('active').prop("disabled", true);
      //    }
      // });
      //
      // list.each(function () {
      //    const ths = $(this);
      //    const isTrue = ths.hasClass('active');
      //
      //    if (isTrue) {
      //       return refs.buttonToApplyFilterPopup.addClass('active').prop("disabled", false);
      //    }
      // });
   });
}

function filterWhipOffHandler () {
   $(document).on('click', '[data-filter-whip-off]', function () {

      filterWhipOff(refs.filterButtonPopup, refs.buttonToApplyFilterPopup);
   });
}

function filterWhipOff (buttons, buttonToApply) {
   buttonToApply.removeClass('active').prop("disabled", true);

   buttons.each(function () {
      const ths = $(this);
      ths.removeClass('active');
   });
}

function filterList (list, target, toApply, parent) {
   target.closest(parent).toggleClass('active');

   list.each(function () {
      const ths = $(this);
      const isTrue = ths.hasClass('active');

      if (!isTrue) {
         return toApply.removeClass('active').prop("disabled", true);
      }
   });

   list.each(function () {
      const ths = $(this);
      const isTrue = ths.hasClass('active');

      if (isTrue) {
         return toApply.addClass('active').prop("disabled", false);
      }
   });
}

function showFilterPopupHandler () {
   $(document).on('click', '.slider-rubric__icon-filter', function (e) {
      const target = $(e.target);
      const currentTarget = $(e.currentTarget);

      currentTarget.closest(".slider-rubric__block").addClass('active');
   });
}

function closeFilterPopupHandler () {
   $(document).on('click', '.filter__icon', function (e) {
      const currentTarget = $(e.currentTarget);

      currentTarget.closest(".slider-rubric__block").removeClass('active');
   });
}

function filterToApplyPopup () {
   $(document).on('click', '[data-filter-to-apply]', function () {
      console.log('to apply poup');
   });
}



