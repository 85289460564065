let searchTimeout;

$(document).on('input', '#search', onChangeSearch);

function onChangeSearch () {
   clearTimeout(searchTimeout)
   searchTimeout = setTimeout(function () {
      console.log('search');
   }, 500)
}

