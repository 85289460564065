/* ============================ sliders.js START ================================================================= */
const sliderEvening = new Swiper('.slider-swipe-evening', {
   // Default parameters
   //к-сть слайдів яку показує
   slidesPerView: 1,
   watchOverflow: true,
   spaceBetween: 10,
   speed: 300,
   slidesPerGroup: 1,
   navigation: {
      nextEl: '.slider-swipe-evening__button-next',
      prevEl: '.slider-swipe-evening__button-prev',
   },
   scrollbar: {
      el: '.slider-swipe-evening__scrollbar',
      draggable: true,
   },
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },
   on: {
      init: function (swiper) {
         if ((swiper.params.loop && swiper.slides.length === 3) || (!swiper.params.loop && swiper.slides.length === 1)) {
            swiper.disable();
            $('.slider-swipe-evening__button-prev').addClass('hidden');
            $('.slider-swipe-evening__button-next').addClass('hidden');
         }
      },
   },
});

const sliderlatestNews = new Swiper('.slider-city-latest-news', {
   slidesPerView: 1.7,
   watchOverflow: true,
   spaceBetween: 20,
   speed: 300,
   slidesPerGroup: 1,
   navigation: {
      nextEl: '.slider-city-latest-news__button-next',
      prevEl: '.slider-city-latest-news__button-prev',
   },
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },
   on: {
      init: function (swiper) {
         if ((swiper.params.loop && swiper.slides.length === 3) || (!swiper.params.loop && swiper.slides.length === 1)) {
            swiper.disable();
            $('.slider-city-latest-news__button-prev').addClass('hidden');
            $('.slider-city-latest-news__button-next').addClass('hidden');
         }
      },
   },
});

const sliderWatchAnotherSeries = new Swiper('.slider-watch-another-series', {
   slidesPerView: 1.2,
   watchOverflow: true,
   spaceBetween: 20,
   speed: 300,
   slidesPerGroup: 1,
   navigation: {
      nextEl: '.slider-watch-another-series__button-next',
      prevEl: '.slider-watch-another-series__button-prev',
   },
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },
   on: {
      init: function (swiper) {
         if ((swiper.params.loop && swiper.slides.length === 3) || (!swiper.params.loop && swiper.slides.length === 1)) {
            swiper.disable();
            $('.slider-watch-another-series__button-prev').addClass('hidden');
            $('.slider-watch-another-series__button-next').addClass('hidden');
         }
      },
   },
});

const sliderYoutube = new Swiper('.slider-youtube', {
   slidesPerView: 1.3,
   watchOverflow: true,
   spaceBetween: 30,
   speed: 300,
   slidesPerGroup: 1,
   navigation: {
      nextEl: '.slider-youtube__button-next',
      prevEl: '.slider-youtube__button-prev',
   },
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },
   on: {
      init: function (swiper) {
         if ((swiper.params.loop && swiper.slides.length === 3) || (!swiper.params.loop && swiper.slides.length === 1)) {
            swiper.disable();
            $('.slider-youtube__button-prev').addClass('hidden');
            $('.slider-youtube__button-next').addClass('hidden');
         }
      },
   },
});

const sliderAbout = new Swiper('.slider-about', {
   slidesPerView: 1.2,
   watchOverflow: true,
   spaceBetween: 20,
   speed: 300,
   slidesPerGroup: 1,
   navigation: {
      nextEl: '.slider-about__button-next',
      prevEl: '.slider-about__button-prev',
   },
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },
   on: {
      init: function (swiper) {
         if ((swiper.params.loop && swiper.slides.length === 3) || (!swiper.params.loop && swiper.slides.length === 1)) {
            swiper.disable();
            $('.slider-about__button-prev').addClass('hidden');
            $('.slider-about__button-next').addClass('hidden');
         }
      },
   },
});

function initSlider (slider) {
   const swipers = $(`.${slider}`);

   swipers.each(function () {
      const ths = $(this);
      const id = ths.attr('id');

      new Swiper(`.${id}`, {
         slidesPerView: 1,
         watchOverflow: true,
         spaceBetween: 20,
         speed: 300,
         slidesPerGroup: 1,
         navigation: {
            nextEl: `.${id}__button-next`,
            prevEl: `.${id}__button-prev`,
         },
         scrollbar: {
            el: `.${id}__scrollbar`,
            draggable: true,
         },
         on: {
            init: function (swiper) {
               if ((swiper.params.loop && swiper.slides.length === 3) || (!swiper.params.loop && swiper.slides.length === 1)) {
                  swiper.disable();
                  $(`.${id}__button-prev`).addClass('hidden');
                  $(`.${id}__button-next`).addClass('hidden');
                  $(`.${id}`).css('padding-top', 0);
               }
            },
         },
      });

   });
}

/* ============================ sliders.js END ================================================================= */

