console.log('apiServer');

const API = {
   filter: 'https://alarm.api.sheep.fish/objects_filtration',
   statusUpdate: 'https://alarm.api.sheep.fish/object_status_update',
   getObjects: 'https://alarm.api.sheep.fish/get_objects',
   statusCount: 'https://alarm.api.sheep.fish/objects_count_by_status',
};

function ajaxUpdateArray () {

   $.ajax({
      url: API.statusUpdate,
      dataType: "json",
      data: JSON.stringify(),
      method: 'POST',
      success: function (data) {
         console.log('data', data);
      },
      error: function (error) {
         console.log(error);
      },
      complete: function () {
      },
   });
}
